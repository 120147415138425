.modal {
  .close {
    font-size: 40px;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 17px;
  }
}

.modal-content {
	border: none;
	border-radius: 0;
}

.modal-body {
	// max-height: calc(100vh - 60px);
 //  overflow-y: auto;
 //  padding-bottom: 30px;
 //  padding-top: 20px;
  @include MQ(XS) {
    padding-left: 30px;
    padding-right: 30px;
  }
  // .modal-votingcard & {
	 //  max-height: calc(100vh - 110px);
  // }
}

.modal-header {
  padding: 0;
  border: none;
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: 500;
    margin-top: 0;
  }
  p {
    font-size: 16px;
    color: #777;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .countdown-timer {
    margin-bottom: 20px;
  }
}

.terms-conditions{
  .modal-header{
    padding:15px 30px;
  }
}