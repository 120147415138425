// Chart colours
$results-blue: #4F9EFA;
$results-orange: #F59D00;
$results-green: #36D67B;
$results-gray: #8D99A4;
$results-red: #E94B35;

$result-for: $results-green;
$result-against: $results-red;
$result-abstain: $results-blue;
$result-open: $results-gray;

.voting-dashboard {
  h1 {
    margin-top: 0;
    font-size: 30px;
    font-weight: normal;
  }
  .alert {
    border-radius: 0;
  }
  .alert-danger {
    background: $brand-danger;
    color: #fff;
  }
  .progress {
    height: 10px;
    border-radius: 8px;
  }
  .progress-bar {
    .attendee-online & {
      background-color: $results-blue;
    }
  }
}

.result-box {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.result-header {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
}

.result-body {
  padding: 30px;
}

.result-text-lg {
  font-weight: 300;
  font-size: 48px;
  line-height: 50px;
  color: #12426C;
}

.attendee-cards {
  margin-left: -15px;
  margin-right: -15px;
  @include MQ(S) {
    display: flex;
    flex-wrap: wrap;
  }
}

.attendee-card-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}

.attendee-card {
  min-height: 190px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;
  @include MQ(S) {
    display: flex;
  }
  .progress {
    margin-bottom: 5px;
  }
}

.attendee-type {
  width: 150px;
}

.card-icon {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  padding: 22px;
  margin-bottom: 10px;
}

.card-title {
  @extend .text-uppercase;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: bold;

}

.card-number {
  font-size: 30px;
  line-height: 30px;
}

.type-online {
  .progress-bar,
  .card-icon {
    background-color: $results-blue;
  }
  .card-number {
    color: $results-blue;
  }
}

.type-card {
  .progress-bar,
  .card-icon {
    background-color: $results-gray;
  }
  .card-number {
    // color: $results-gray;
  }
}

.type-mobile {
  .progress-bar,
  .card-icon {
    background-color: $results-orange;
  }
  .card-number {
    color: $results-orange;
  }
}

.type-phone {
  .progress-bar,
  .card-icon {
    background-color: $results-green;
  }
  .card-number {
    color: $results-green;
  }
}


.progress-bar {
  box-shadow: none;
}

.result-line-graph {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  // &:nth-child(2) {
  //   animation-delay: 300ms;
  // }
  .progress-wrapper {
    flex: 1;
  }
  .progress {
    height: 18px;
    border-radius: 15px;
    margin-bottom: 0;
  }
  .progress-bar {
    text-align: left;
    transition: width 1000ms ease;
    transition-delay: 300ms;
    span {
      margin-left: 10px;
      font-size: 13px;
      font-weight: bold;
      position: relative;
      top: -1px;
    }
  }
}

.result-badge-wrapper-label,
.result-badge-wrapper {
  width: 100px;
}

.result-badge-wrapper-label,
.progress-wrapper-labels,
.result-result-label {
  @extend .text-muted; 
  @extend .text-uppercase; 
  @extend .text-sm; 
}

.result-result-labels,
.result-results {
  width: 200px;
  display: flex;
  justify-content: center;
}

.result-result-label,
.result-result {
  width: 50%;
  text-align: center;
}

.result-result {
  font-weight: bold;
}

.progress-wrapper-labels {
  flex: 1;
}

.result-badge {
  background: #666;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 17px;
  .for & {
    background-color: $result-for;
  }
  .against & {
    background-color: $result-against;
  }
  .abstain & {
    background-color: $result-abstain;
  }
}

.label-for {
  color: $result-for;
}

.label-against {
  color: $result-against;
}

.label-abstained {
  color: $result-abstain;
}

.label-open {
  color: $result-open;
}

.progress-bar-for {
  background-color: $result-for;
}

.progress-bar-against {
  background-color: $result-against;
}

.progress-bar-abstain {
  background-color: $result-abstain;
}

.progress-bar-open {
  background-color: $result-open;
}

.progress-bar-answered {
  background-color: $results-green;
}

.progress-bar-reviewd {
  background-color: $results-blue;
}

.progress-bar-unanswered {
  background-color: #eee;
}

// Charts

.result-chart-wrapper {
  padding: 30px 0;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.result-chart-title-wrapper {
  width: 70%;
  margin-left: auto;
  margin: auto;
  text-align: center;
  margin-top: -40px;
  @include MQ(S) {
    margin-top: -80px;
  }
}

.result-chart-title {
  font-size: 20px;
  line-height: 20px;
  @include MQ(S) {
    font-size: 50px;
    line-height: 50px;
  }
}

.result-chart-label {
  text-transform: uppercase;
  color: #888;
}