.btn-logout {
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0.8);
  &:hover {
    background: rgba(0,0,0,0.2);
    color: #fff;
  }
}

.btn-minimal {
  background: transparent;
  color: $blue-dark;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.btn-blue {
  background: $blue-dark;
  color: #fff;
  &:hover, &:focus {
    color: #fff;
    background: darken($blue-dark, 5%);
  }
}

.btn-lg {
  border-radius: 4px;
}

.btn-add{
  color:$blue-light;
  font-weight: bold;
}