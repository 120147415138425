#votingCard {
  .modal-header {
    background: $blue-dark;
    padding: 15px 60px 15px 30px;
    color: #fff;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      opacity: 1;
      background: darken($blue-dark,5%);
      height: 49px;
      width: 50px;
      margin: 0;
      &:hover {
        background: darken($blue-dark,10%);
      }
      @include MQ(Sneg) {
        background: none !important;
      }
    }
    .hrn, .name {
      @include MQ(Sneg) {
        float: none !important;
      }
    }
  }
  .modal-content {
    background: #f0f0f0;
    border: none;
    border-radius: 0;
  }
  .modal-body {
    padding: 0;
  }
}

.new-resolutions-notification {
  background: $brand-warning;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 17px;
  a {
    color: darken($brand-warning, 30%);
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #333;
    }
  }
}


.voting-card-vote {
  .nav-tabs {
    text-align: center;
    li {
      display: inline-block;
      float: none;
      a {
        font-size: 18px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .card-header {
    padding: 15px 30px 0 30px;
    p {
      margin-bottom: 0;
    }
  }
  .tab-content {
    background: #fff;
    padding: 25px 10px;
    max-height: 600px;
    overflow-y: scroll;
    @include MQ(S) {
      padding: 30px;
    }
  }
  .btn-primary {
    background: $company-default;
    border-radius: 0;
    border-color: darken($company-default, 5%);
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    &:hover {
      background: darken($company-default, 5%);
    }
  }
  .btn-lg {
    border-radius: 0;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

.resolution {
  background: #f9f9f9;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  .inner {
    border-left: 5px solid #f0f0f0;
  }
}

.resolution-header {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  .title {
    @include MQ(Sneg) {
      margin-bottom: 5px;
    }
    @include MQ(S) {
      float: left;
      width: 35%;
    }
    h4 {
      font-weight: 700;
      margin-bottom: 7px;
    }
  }
  .vote-btns {
    @include MQ(S) {
      float: right;
      width: 65%;
    }
  }
  .btn-tools {
    position: relative;
    color: #fff;
    background: $blue-dark;
    border: 1px solid #222;
    margin-left: 5px;
    font-size: 14px;
    padding: 8px 15px;
    text-align: left;
    padding-left: 30px;
    @include MQ(Sneg) {
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 5px;
      display: block;
      font-size: 16px;
    }
    @include MQ(S) {
      width: 32%;
    }
    @include MQ(S) {
      width: 100px;
      margin: 0;
    }
    &.active {
      color: #fff;
    }
    .fa {
      position: absolute;
      top: 10px;
      left: 7px;
      font-size: 15px;
    }
  }
  .for.active {
    background: $brand-success;
    border-color: darken($brand-success, 10%);
  }
  .against.active {
    background: $brand-danger;
    border-color: darken($brand-danger, 10%);
  }
  .abstain.active {
    background: $brand-warning;
    border-color: darken($brand-warning, 10%);
  }
  .votes-remaining {
    clear: both;
    text-align: right;
    padding-top: 5px;
    padding-right: 4px;
  }
}

.resolution.for {
  border-color: $brand-success;
  .inner {
    border-color: $brand-success;
  }
}

.resolution.against {
  border-color: $brand-danger;
  .inner {
    border-color: $brand-danger;
  }
}

.resolution.abstain {
  border-color: $brand-warning;
  .inner {
    border-color: $brand-warning;
  }
}

.resolution-body {
  padding: 15px;
  color: #888;
  font-size: 12px;
}

// Partial votes

.resolutions.partial {
  .vote-btns {
    .input-group {
      @include MQ(Sneg) {
        width: 100%;
        margin: 0 0 5px 0;
        .btn-tools {
          margin: 0;
          height: 40px;
        }
        .form-control {
          height: 40px;
        }
      }
      @include MQ(S) {
        width: 100px;
        float: left;
        margin-right: 3px;
        .btn-tools {
          width: 100%;
          display: block;
        }
      }
      &.for {
        .input-group-addon {
          background: $brand-success;
          border-color: $brand-success;
        }
        .form-control {
          border-color: $brand-success;
        }
      }
      &.against {
        .input-group-addon {
          background: $brand-danger;
          border-color: $brand-danger;
        }
        .form-control {
          border-color: $brand-danger;
        }
      }
      &.abstain {
        .input-group-addon {
          background: $brand-warning;
          border-color: $brand-warning;
        }
        .form-control {
          border-color: $brand-warning;
        }
      }
    }
    .input-group-addon {
      padding: 6px;
      color: #fff;
      background: $blue-dark;
      font-size: 16px;
    }
    .form-control {
      font-size: 12px;
      height: 37px;
    }
  }
}