.home {
  background: url(../images/default-bg.jpg) no-repeat center center fixed;
  background-size: cover;

  .container {
    max-width: 800px;
  }

}

.agm-list-masthead {
  margin-bottom: 20px !important;
  @include MQ(S) {
    padding: 20px 0;
  }
  @include MQ(Sneg) {
    margin-bottom: 50px;
    text-align: center;
  }
  h1 {
    margin-bottom: 0;
  }
  .logo-link {
    color: rgba(255,255,255,0.5);
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

.agm-list {
  max-width: 970px;
  .agm-list-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #fff;
  }
  .agm-col {
    @include MQ(S-M) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include MQ(M) {
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
  h1 {
    margin-bottom: 0;
  }
}

.contact-details {
  // float: right;
  background: rgba(0,0,0,0.3);
  border-radius: 4px;
  padding: 10px 10px;
  @include MQ(Sneg) {
    margin-top: 5px;
    text-align: center;
  }
  @include MQ(S) {
    float: right;
    padding: 10px 40px;
  }
  .contact-title {
    color: rgba(255,255,255,0.5);
    padding-bottom: 2px;
    margin-bottom: 2px;
    @include MQ(Sneg) {
      display: inline-block;
      margin-right: 5px;
    }
    @include MQ(S) {
      border-bottom: 1px solid rgba(255,255,255,0.3);
    }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    @include MQ(Sneg) {
      display: inline-block;
    }
    @include MQ(S) {
      font-size: 24px;
    }
    span {
      color: #fff;
    }
  }
}

.no-agms {
  font-size: 20px;
  margin-top: 40px;
  color: rgba(255,255,255,0.5);
}

.agm-list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin: 0;
    padding: 0;
  }
  .agm-list-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #fff;
    text-align: center;
  }
  .agm {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    display: block;
    color: $text-color;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.3);
    transition: background 500ms ease;
    margin-bottom: 30px;
    @include MQ(Sneg) {
      margin-left: auto;
      margin-right: auto;
    }
    &:hover {
      text-decoration: none;
      background: #f5f5f5;
      .agm-link {
        background: $blue-light;
      }
    }
    &:focus {
      text-decoration: none;
    }
    .agm-logo {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 220px;
      text-align: center;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 3px;
      @include MQ(S) {
        font-size: 24px;
      }
    }
    .agm-date {
      font-size: 16px;
      color: #666;
    }
    .agm-content {
      padding: 20px 30px;
    }
    .agm-link {
      // color: $link-color;
      background: #eee;
      padding: 10px;
      background: $blue-dark;
      color: #fff;
      padding: 15px;
      margin-top: 10px;
      border-radius: 4px;
      border-top: 1px solid #ddd;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 13px;
      position: relative;
      bottom: -1px;
      text-align: center;
    }
  }
}