.agm-screen {
  .masthead {
    margin-bottom: 0;
    .logo {
      // float: left;
      max-width: 200px;
      @include MQ(Sneg) {
        width: 180px;
        margin-left: auto; 
        margin-right: auto;
        margin-bottom: 15px;
        text-align: center;
      }
      @include MQ(S) {
        float: left;
      }
      img {
        max-width: 190px;
        max-height: 50px;
        @include MQ(S) {
          max-width: 90px;
          max-height: 95px;
        }
        @include MQ(M) {
          max-width: 190px;
          max-height: 50px;
        }
      }
    }
    .btn {
      border-radius: 0;
      margin-left: 7px;
      margin-bottom: 5px;
    }
    .btn-default {
      background: none;
      border-color: $company-default;
      color: $company-default;
      &:hover {
        background: darken($blue-dark, 5%);
      }
    }
    .btn-primary {
      background: $company-default;
      border-color: $company-default;
      &:hover {
        background: darken($company-default, 5%);
      }
    }
    .btn-lg {
      padding: 6px 12px;
      @include MQ(XS) {
        padding: 10px 20px;
      }
      @include MQ(L) {
        padding: 14px 40px;
      }
    }
  }
  .main-content {
    padding: 50px 0;
    background: #fff;
    @include MQ(S) {
      padding: 15px 0;
    }
    @include MQ(L) {
      padding: 30px 0;
    }
  }
  .branding {
    @include MQ(S) {
      margin-left: 93px;
	  padding: 0 10px;
    }
    @include MQ(M) {
	  float:left;
      margin-left: 10px;
    }
    @include MQ(Sneg) {
      text-align: center;
      margin-bottom: 15px;
    }
    h1 {
      font-size: 23px;
      font-weight: normal;
      @include MQ(M) {
        font-size: 30px;
      }
    }
  }
  .masthead-links {
    @include MQ(S) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    @include MQ(Sneg) {
      text-align: center;
      a {
        display: inline-block;;
      }
    }
  }
}

.video-wrapper {
  background: transparent;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  //overflow-x: auto;
  text-align: center;
  @include MQ(S) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .inner {
    margin: 0 auto;
  }

}

.just-video {
  iframe {
    width: 300px;
    height: 72%;
    @include MQ(XS) {
      width: 600px;
      height: 90%;
    }
    @include MQ(S) {
      width: 700px;
      height: 85%;
    }
    @include MQ(M) {
      width: 1000px;
      height: 85%;
    }
    @include MQ(L) {
      width: 100%;
      height: 80%;
    }
    @include MQ(XL) {
      width: 100%;
      height: 55%;
    }
  }
}

.video-and-slide {
  .iframe-wrapper {
    @include MQ(S) {
      float: left;
      width: 50%;
    }
  }
  iframe {
    width: 300px;
    height: 200px;
    @include MQ(XS) {
      width: 600px;
      height: 220px;
    }
    @include MQ(S) {
      width: 350px;
      height: 270px;
    }
    @include MQ(M) {
      width: 470px;
      height: 398px;
    }
    @include MQ(L) {
      width: 570px;
      height: 398px;
    }
    @include MQ(XL) {
      width: 600px;
      height: 398px;
    }
  }
}

.iframe-wrapper-left {
  @include MQ(Sneg) {
    padding-bottom: 10px;
  }
  @include MQ(S) {
    text-align: right;
    padding-right: 15px;
  }
}

.iframe-wrapper-right {
  @include MQ(S) {
    text-align: left;
    padding-left: 15px;
  }
}

.video-mobile-link {
  display: none;
  margin-bottom: 0;
}

.touch {
  .video-mobile-link {
    display: block;
    padding-bottom: 10px;
    span {
      @include MQ(XS) {
        display: none;
      }
    }
  }
}

.agm-actions {
  padding: 2.5rem 0 5rem;
  text-align: center;
  .card {
    min-width: 170px;
    min-height: 150px;
    vertical-align: top;
    display: inline-block;
    margin: 0 10px 20px 10px;
    @include MQ(XSneg) {
      display: block;
    }
    @include MQ(XS) {
      max-width: 170px;
    }
  }
  .voting-card-link {
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    background-color: #F6F6F6;
    position: relative;
    color: #333333;
    @include MQ(XSneg) {
      display: block;
      margin-bottom: 10px;
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    .flag {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #eee;
      border-top: transparent;
      //margin-top: 13px;
      padding: 10px 4px;
      color: #fff;
      z-index: 1;
      font-weight: 600;
    }
    .vote-not-submitted {
      transition: all 1000ms ease;
    }
    .vote-submitted {
      background: $brand-success;
      border-top: 1px solid darken($brand-success, 10%);
      color: #fff;
      display: none;
    }
    .vote-error {
      background: $brand-danger;
      border-top: 1px solid darken($brand-danger, 10%);
      color: #fff;
      display: none;
    }
    .vote-update-required {
      background: $brand-warning;
      border-top: 1px solid darken($brand-warning, 10%);
      color: #fff;
      display: none;
    }
    .question-comment-received {
      background: $brand-success;
      border-top: 1px solid darken($brand-success, 10%);
      color: #fff;
      display: none;
    }
    &.has-new-comments {
      .question-comment-received {
        display: block;
      }
    }
    &.submitted {
      .vote-submitted {
        display: block;
      }
      .vote-not-submitted {
        display: none;
      }
    }
    &.update-required {
      .vote-update-required {
        display: block;
      }
      .vote-not-submitted {
        display: none;
      }
      .vote-submitted {
        display: none;
      }
    }
    &.error {
      .vote-error {
        display: block;
      }
      .vote-submitted {
        display: none;
      }
      .vote-not-submitted {
        display: none;
      }
      .vote-update-required {
        display: none;
      }
    }
    &.prompt {
      animation-name: bounce;
      transform-origin: center bottom;
      animation-duration: 1000ms;
      animation-fill-mode: both;
      .vote-not-submitted {
        background: $brand-warning;
      }
    }
    .voting-card-header {
      background-color:#F6F6F6;
      text-transform: uppercase;
      color: #333;
      font-weight: 600;
      padding: 10px 15px;
      //margin-bottom: 10px;
      text-align: left;
      font-size: 12px;
      border-bottom: none;
      text-align: center;
      .title {
        font-weight: bold;
        max-width: 190px;
        word-break: break-word;
        color: #333;
        font-weight: 600;
        margin: 0 auto;
        height: 30px;
      }
      .srn {
        color: #333;
        font-weight: 600;
      }
    }
    .btn {
      color: #333333;
      font-size: 9px;
      text-transform: uppercase;
      display: block;
      padding-bottom: 7px;
      padding-top: 7px;
      word-break: break-word;
      white-space: normal;
      background: #F6F6F6;
      min-height: auto;
      border: 1px solid #333333;
      font-weight: 600;
      line-height: 22px;
      max-width: 80%;
      margin: 0 auto;
      i {
        margin-right: 3px;
        position: relative;
        top: -2px;
      }
      &:hover {
        //background: #eee;
        color: $link-color;
      }
      &.btn-view-questions {
        padding-top: 7px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        font-size: 9px;
      }
    }
    .throbber {
      display: none;
    }
    &.submitting {
      .throbber {
        display: block;
      }
    }
  }
  div.voting-card-link {
    &:hover {
      cursor: default;
    }
  }
  .card-link.footer-box {
    padding: 84px 15px;
    border: 1px solid transparent;
    width: auto;
    max-width: 100%;
    border-radius: 20px;
    width: 350px;
    transition: 0.2s all;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
    //transition: all ease 500ms;
    @include MQ(XSneg) {
      display: block;
      margin-bottom: 10px;
    }
    i {
      //color: #d9d9d9;
      display: block;
      font-size: 36px;
      margin-bottom: 6px;
    }
    &:hover {
      text-decoration: none;
      // background: $blue-dark;
      // border-color: darken ($blue-dark, 10%);
      //color: #fff;
      cursor: pointer;
      i {
        //color: #fff;
      }
    }
  }
  .card-has-hover:hover {
    background: $blue-dark;
    border-color: darken ($blue-dark, 10%);
  }
  .card-downloads {
    padding-top: 20px;
    padding-bottom: 0px;
    //text-align: left;
    background: transparent !important;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
    h3 {
      margin-top: 0;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 27px;
      margin-bottom: 30px;
      i {
        display: inline-block;
        font-size: 20px;
        margin-right: 7px;
      }
    }
    .document-list {
      text-align: left;
      font-size: 16px;
      li {
	      font-size: 14px;
        a {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 22px;
          display: block;
          padding-bottom: 20px;
        }
      }
      i {
        display: inline-block;
        font-size: 20px;
        margin-right: 5px;
      }
      a {
        &:hover {
          text-decoration: none;
          // i {
          //   color: $link-hover-color;
          // }
        }
      }
    }
    &:hover {
      //background: #fff;
      i {
        //color: #d9d9d9;
      }
      h3 {
        //color: #adadad;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.countdown-timer {
  background: $brand-danger;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-size: 20px;
}

.notifcations {
  position: relative;
  z-index: 9999;
}

.submission-notification {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $brand-warning;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  animation-name: fadeOut;
  animation-duration: 1s;
  // animation-fill-mode: both;
  animation-delay: 4s;
}

.question-submitted-notification {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $brand-success;
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding: 10px;
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 4s;
}

.throbber {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.9);
  text-align: center;
  color: $text-color;
  font-size: 16px;
  padding-top: 80px;
  z-index: 2;
  &:before {
    animation: fa-spin 2s infinite linear;
    content: "\f110";
    font-family: FontAwesome;
    font-size: 30px;
    position: absolute;
    top: 35px;
    width: 100%;
    right: 0;
  }
}

.agm-actions .col-md-4 .col-md-6 {
  padding: 0;
}

