// Management page

.management {
  @include MQ(Sneg) {
    padding-left: 0;
    padding-right: 0;
  }
  @include MQ(Mneg) {
    width: 100%;
  }
  @include MQ(M) {
    max-width: 970px;
  }
  .dashboard-header {
    position: relative;
    .question-links {
      // margin-top: 20px;
      background: #fff;
      border: 1px solid #ddd;
      // box-shadow: 0 0 1px 1px rgba(0,0,0,0.05);
      text-align: center;
      // margin-bottom: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      // border: 4px solid #ddd;
      //background: #e6e6e6;
      @include MQ(Sneg) {
        flex-wrap: wrap;
      }
      li {
        margin: 0;
        float: none;
        flex: auto;
        &:last-child {
          .btn {
            @include MQ(S) {
              // border-right: none;
              // border-top-right-radius: 15px;
              // border-bottom-right-radius: 15px;
            }
          }
        }
      }
      .nya-bs-select {
        width: 160px;
      }
      .btn {
        border: none;
        border-radius: 0;
        padding: 10px;
        font-size: 17px;
        background: #fff;
        color: #444;
        border-right: 1px solid #ddd;
        // margin-left: 1px;
        // margin-right: 1px;
        border-top: none;
        // border-bottom: 4px solid transparent;
        @include MQ(Sneg) {
          // margin-bottom: 3px;
          // margin-right: 3px;
          // font-size: 13px;
          // padding: 9px;
        }
        &.active {
          color: $link-color;
          background: #fff;
          position: relative;
          // border-bottom-color: $link-color;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5px;
            background: $link-color;
          }
        }
      }
    }
  }
}

// .question-column {
//   max-width: 700px;
//   margin: 0 auto;
// }

.question-heading {
  font-size: 30px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
}

.question-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

.question-column {
  background: #fff;
  border: 1px solid #ddd;
  // box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.05);
}

.question-column-header {
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  @include MQ(Sneg) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include MQ(S) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filter-label {
  font-weight: bold;
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
}

.question-filters {
  .nya-bs-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 170px;
  }
  .nya-bs-select > .btn {
    background: #f5f5f5;
    border: 1px solid #eee;
  }
}

.global-question-filter-controls {
  font-size: 14px;
  color: #aaa;
  font-weight: 500;
  // letter-spacing: 0.5px;
  text-transform: uppercase;
  // @include MQ(S) {
  //   padding: 14px 10px 10px 10px;
  // }
  label {
    text-transform: uppercase;
    font-weight: 700;
    color: #666;
    display: none;
  }
  button {
    background: transparent;
    padding: 0 7px;
    border: none;
    // padding: 0 7px;
    // margin: 0 3px;
    // border: none;
    // text-transform: uppercase;
    &.active-descending,
    &.active-ascending {
      color: $link-color;
      position: relative;
      &:before {
        font-family: FontAwesome;
        position: absolute;
        right: -2px;
        top: 1px;
      }
    }
    &.active-descending {
      &:before {
        content: '\f175';
      }
    }
    &.active-ascending {
      &:before {
        content: '\f176';
      }
    }
    &:focus {
      outline: none;
    }
  }
}

.questions {
  padding: 0 10px 10px 10px;
  @include MQ(S) {
    padding: 0 50px 15px 50px;
  }
  @include MQ(L) {
    padding: 0 100px 15px 100px;
  }
  background: #fff;
}

.question-list,
.archived-questions {
  .no-result {
    text-align: center;
    color: #999;
    padding-top: 15px;
    padding-bottom: 30px;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 25px;
  }
  .question {
    background: #fff;
    margin-bottom: 15px;
    // box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    .question-header {
      border-bottom: 1px solid #ccc;
      background: #eee;
      padding: 15px 30px 5px 30px;
      position: relative;
    }
    .title {
      h4 {
        text-align: left;
        // color: #5b6a75;
        font-weight: 500;
        margin-bottom: 30px;
        //font-style: italic;
        span {
          font-weight: 500;
        }
      }
      h5 {
	      font-style: italic;
	      em {
		      font-weight: bold;
	      }
      }
    }
    .tools {
      margin-bottom: 5px;
      overflow: hidden;
      // text-align: center;
      .inner {
        //float: right;
        i {
          margin-right: 3px;
        }
      }
      .btn-info {
        background: #fff;
        border-color: #ddd;
        color: #777;
      }
      .btn {
        margin-bottom: 3px;
        border-radius: 2px;
        &.to-be-answered {
          background-color: $brand-primary;
          border-color: darken($brand-primary, 10%);
          color: #fff;
        }
        &.answered {
          background-color: $brand-success;
          border-color: darken($brand-success, 10%);
          color: #fff;
        }
        &.archived {
          background-color: $brand-danger;
          border-color: darken($brand-danger, 10%);
          color: #fff;
        }
        &.on-teleprompter {
          background-color: $brand-success;
          border-color: darken($brand-success, 10%);
          color: #fff;
        }
      }
      .btn-comments {
	      margin: 5px 0 0 5px;
        float: right;
	      cursor: pointer;
        i {
	        margin: 0;
        }
      }
    }
    .question-body {
      font-size: 17px;
      padding: 15px 30px 15px 30px;
    }
  }
}

.question-meta {
  font-size: 12px;
  color: #888;
  font-weight: normal;
  // padding-top: 20px;
  .item {
    display: inline-block;
    margin-right: 10px;
  }
}

// Question form

.question-form {
	padding: 10px 15px;
  @include MQ(XS) {
    padding-left: 50px;
    padding-right: 50px;
  }
  .btn {
    border-radius: 0;
  }
  .regarding-select {
    ol {
      padding-left: 5px;
    }
    .dropdown-toggle {
      border-color: #ccc !important;
      color: $text-color !important;
    }
  }
  // .close {
  //   top: 5px !important;
  // }
}

// Submitted Questions Card

.voting-card-link {
  .badge {
    color: #fff;
    background: #000;
    margin-right: 4px;
    font-size: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    line-height: 14px;
    top: 0px;
  }
  &:hover {
    .badge {
      background: $blue-light;
    }
  }
}

// Teleprompter

.teleprompter-questions {
  background: #fff;
  padding: 20px 50px 50px 50px;
  h4 {
    font-size: 26px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    color: #888;
    span {
      font-weight: bold;
      color: #333;
    }
  }
  .question-body {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
  }
  .no-result {
    font-size: 20px;
    text-align: center;
    font-style: italic;
  }
}

.teleprompter-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
  .next-question {
    text-align: center;
  }
  .btn {
    font-size: 20px;
    span {
      font-size: 16px;
      color: $blue-dark;
      font-weight: bold;
    }
  }
  .question-count {
    font-weight: bold;
    padding-top: 5px;
  }
}


.view-questions {
}

.question {
  background: #fff;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  &:last-of-type {
	  .sidebar & {
	  	margin-bottom: 0;
	  }
  }
  .inner {
	  > div:last-of-type {
		  border-bottom: none;
	  }
  }
  p {
	  font-weight: 300;
  }
}

.question-body {
  border-bottom: 1px solid #c9c9c9;
  padding: 15px;
  .title {
    @include MQ(Sneg) {
      margin-bottom: 5px;
    }
    h4 {
	    color: $text-color;
	    font-size: 17px;
	    font-weight: 500;
	    line-height: 1.4;
      margin: 0 0 10px 0;
      overflow-wrap: break-word;
    }
    h5 {
	    // color: $neutral-color;
	    font-size: 13px;
      font-weight: normal;
      font-style: italic;
	    // font-weight: 300;
	    // line-height: 1.4;
	    // font-style: italic;
      // margin: 0 0 5px 0;
      span {
	      // text-transform: uppercase;
      }
      em {
	      font-weight: bold;
	      font-style: inherit;
      }
    }
    .btn-comments {
	    margin: 0 0 10px 20px;
	    float: right;
    }
  }
  .vote-btns {
    @include MQ(S) {
      float: right;
      width: 65%;
    }
  }
  .btn-tools {
    position: relative;
    color: #fff;
    background: $blue-dark;
    border: 1px solid #222;
    margin-left: 5px;
    font-size: 14px;
    padding: 8px 15px;
    text-align: left;
    padding-left: 30px;
    @include MQ(Sneg) {
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 5px;
      display: block;
      font-size: 16px;
    }
    @include MQ(S) {
      width: 32%;
    }
    @include MQ(S) {
      width: 102px;
      margin: 0;
    }
    &.active {
      color: #fff;
    }
    .fa {
      position: absolute;
      top: 10px;
      left: 7px;
      font-size: 15px;
    }
  }
}

.question-body {
  color: #888;
  font-size: 14px;
  font-weight: 300;
  padding: 15px;
  background: #fff;
  p.markup-text {
    white-space: pre-line;
  }  
}

.question-comments {
  padding: 15px 20px;
  background: #e9eaee;
  border-bottom: solid 1px #cfcfcf;
  form {
	  position: relative;
		// &:before {
		// 	content: '';
		// 	display: block;
		// 	width: 0;
		// 	height: 0;
		// 	border-style: solid;
		// 	border-width: 10px 0 10px 10px;
		// 	border-color: transparent transparent transparent #fff;
		// 	position: absolute;
		// 	top: 0;
		// 	right: -10px;
		// }
		textarea {
			font-size: 14px;
			font-weight: normal;
			width: 100%;
			margin: 0 0 6px 0;
			padding: 15px;
			display: block;
			border: none;
			resize: none;
			position: relative;
      &:focus {
        outline: none;
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
      }
		}
		.button-wrapper {
			width: 100%;
			justify-content: flex-end;
      .btn {
        // width: 100%;
        // display: block;
        padding: 6px 30px;
        font-weight: normal;
        border-radius: 0;
        text-transform: uppercase;
      }
			.management & {
				justify-content: space-between;
			}
		}
		// button {
		// 	color: #fff;
		// 	font-size: 12px;
		// 	font-weight: 300;
		// 	text-transform: uppercase;
		// 	margin: 0 0 0 6px;
		// 	padding: 8px 30px;
		// 	background: $neutral-color;
		// 	border: none;
		// 	outline: none;
		// 	&.btn-basic {
		// 		&:hover {
		// 			background: darken($neutral-color,6%);
		// 		}
		// 	}
		// 	.management & {
		// 		margin: 0;
		// 		width: calc(50% - 3px);
		// 	}
		// }
	}
}
.commentstoggle-wrapper {
	.btn-commentstoggle {
		width: 100%;
		padding: 8px 30px;
		display: block;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		background: #e2e3e7;
    color: $blue-dark;
		&:hover, &:focus {
			text-decoration: none;
			background: #ddd;
		}
	}
}

.btn-comments {
  display: flex;
  align-items: center;;
  .small {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    margin-left: 3px;
  }
}

.comment-icon {
	color: #fff;
	line-height: 22px;
	text-align: center;
	width: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
	height: 22px;
	background: #bbb;
	border-radius: 4px 4px 4px 0;
	position: relative;
    padding: 4px 0;
	&:after {
		position: absolute;
		left: 0;
		bottom: -5px;
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 0;
		border-color: #bbb transparent transparent transparent;
	}
  .has-comments & {
	  i {
		  display: none;
	  }
  }
  .has-unanswered-comment & {
	  background: $blue-light;
		&:after {
			border-color: $blue-light transparent transparent transparent;
		}
	  i {
		  display: none;
	  }
  }
	.sidebar-tab & {
		color: $blue-light;
		background: #fff;
		&:after {
			border-color: #ffffff transparent transparent transparent;
		}
	}
}

.comments-list {
	margin: 0;
	padding: 0;
	li {
		// color: #999;
		margin: 0 0 15px 0;
		background: #fff;
    border: 1px solid #ddd;
		list-style: none;
		position: relative;
    font-weight: normal;
    p {
      padding: 10px 15px;
      font-size: 14px;
      font-weight: normal;
    }
		.meta {
			color: #777;
			font-size: 12px;
			font-weight: 300;
			&.commenter {
				text-align: right;
			}
		}
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 0 11px 11px;
      border-color: transparent transparent transparent #ddd;
      position: absolute;
      top: 0;
      right: -11px;
    }
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 0 10px 10px;
			border-color: transparent transparent transparent #fff;
			position: absolute;
			top: 1px;
			right: -10px;
		}
		&.their-comment {
			color: #f0f0f0;
			background: #5b6a75;
			.comment-metas {
        border-top: 1px solid #999;
				color: rgba(255,255,255,0.8);
			}
      &:after {
        border-color: transparent;
      }
			&:before {
				border-width: 10px 10px 10px 0;
				border-color: transparent #5b6a75 transparent transparent;
				right: auto;
				left: -10px;
			}
		}
	}
}

.comment-metas {
  display: flex;
  // background: rgba(0,0,0,0.1);
  border-top: 1px solid #ddd;
  color: #777;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 15px;
}

.commet-meta-commenter {
  margin-left: auto;
}

.board-question-filter a {
  margin-left: 5px;
  margin-right: 5px;
  color: #666;
  //background-color: #f0f0f0;
  //border: 1px solid #ddd;
  padding: 6px 12px;
  //border-radius: 4px;
  display: block;
  .badge {
    background-color: #999;
    margin: 0 5px;
  }
  &.active {
    color: $link-color;
    //background-color: $link-color;
    //border-color: darken($link-color, 5%);
    //color: #fff;
    .badge {
      background-color: darken($link-color, 10%);
    }
  }
}

// Phone Participants


// .questions-wrapper {
//   width: 100%;
//   max-width: 1240px;
//   padding: 20px 40px;
//   @include MQ(S) {
//     padding: 30px 100px;
//   }
// }

.phone-list {
  // width: 300px;
  // background: #fff;
  // border-right: 1px solid #ddd;
  // padding: 30px 20px;
  h2 {
    font-size: 22px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
  }
}

.call-participant {
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}



.call-participant-details {
  width: 100%;
  padding: 10px 15px;
}

.call-participant-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.call-participant-company {
  color: #999;
}

.call-participant-status {
  font-size: 20px;
  width: 60px;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px 25px;
  .talking & {
    background-color: $green;
    background-image: url(../images/mic.svg);
  }
  .hand-raised & {
    background-color: #aaa;
    background-image: url(../images/hand.svg);
  }
  .on-call & {
    background-color: #ddd;
    background-image: url(../images/phone.svg);
  }
}

.telepromoter-phone-mode {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .call-participant {
    margin-bottom: 15px;
  }
  .call-participant.talking {
    .call-participant-details {
      padding: 20px 30px
    }
    .call-participant-name {
      font-size: 24px;
      line-height: 26px;
    }
    .call-participant-company {
      font-size: 20px;
    }
  }
  .call-participant.hand-raised {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}