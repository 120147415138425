.dashboard-reports {
  margin-top: 40px;
}

.dashboard-report {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 0  0 4px 4px;
}

.report-tab-link {
  font-size: 20px;
  margin-bottom: -1px !important;
  text-align: left !important;
  padding-left: 25px !important;
  background: #e6e6e6;
  border-color: #ddd !important;
}

#reportTabs {
  li {
    position: relative;
  }
  li.active {
    a {
      border-bottom-color: transparent !important;
    }
    .btn-refresh {
      display: block;
    }
  }
  .btn-refresh {
    background: $brand-primary;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
  }
}