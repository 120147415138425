$company-primary: #CC0A8B;
//$company-secondary:

body.login {
  background: no-repeat center center fixed;
  background-size: cover;
  color: $text-color;
  background-position: center center;
  background-attachment: fixed;
  // @include MQ(Sneg) {
  //   background-image: none !important;
  // }
}

.login-wrapper {
  max-width: 480px;
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 35px;
  // @include MQ(Sneg) {
  //   background-color: #fff;
  // }
  @include MQ(S) {
    padding-top: 40px;
  }
  .logo {
    margin-bottom: 15px;
    float: none;
    width: auto;
    @include MQ(S) {
      margin-bottom: 30px;
    }
  }
  h1 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    @include MQ(S) {
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  h2 {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    @include MQ(S) {
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 18px;
    }
  }
  .input-group {
    margin-bottom: 15px;
  }
  .form-group {
    text-align: left;
  }
  .control-label {
    font-size: 18px;
    font-weight: 500;
    display: none;
  }
  .form-control,
  .input-group-addon {
    height: 45px;
    border: none;
    border-radius: 0;
    background: rgba(0,0,0,0.5);
    box-shadow: rgba(0,0,0,0.1) 1px 1px 1px inset;
    font-size: 18px;
    color: #ccc;
  }
  // Placeholder text color
  .form-control::-webkit-input-placeholder {
     color: #ccc;
  }

  .form-control:-moz-placeholder {
     color: #ccc;
  }

  .form-control::-moz-placeholder {
     color: #ccc;
  }

  .form-control:-ms-input-placeholder {
     color: #ccc;
  }
  .btn {
    padding: 15px;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
  }
  .help-block {
    text-align: left;
  }
  .modal {
    .modal-header {
      padding: 15px 30px;
      border-bottom: 1px solid #e5e5e5;
      h3 {
        font-size: 20px;
      }
    }
    textarea {
      width: 100%;
      height: 280px;
      border: none;
      resize: none;
    }
    .modal-footer {
      text-align: center;
      .btn {
        padding: 10px 15px;
        border-radius: 4px;
      }
    }
  }
}

.login-form-wrapper {
  background: #fff;
  padding: 10px 15px 25px 15px;
  @include MQ(Sneg) {
    margin-left: -15px;
    margin-right: -15px;
  }
  @include MQ(S) {
    padding: 30px 40px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    border-radius: 4px;
  }
}

.terms-conditions {
  padding-bottom: 15px;
  input {
    margin-right: 5px;
  }
  #tc-modal {
    color: $text-color;
  }
  .tc-trigger {
    background: none;
    border: none;
    text-decoration: underline;
    &:hover, &:focus {
      color: #000;
      outline: none;
    }
  }
}


// Dashboard login

body.auth-login {
  background: url(../images/default-bg.jpg) no-repeat center center fixed;
  background-size: cover;
}

.dashboard-login-wrapper {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
  background: #f7f7f7;
  padding: 20px 80px 40px;
  h1 {
    color: #666;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
    color: #666;
    font-weight: 300;
  }
  .form-group {
    text-align: left;
  }
  .form-control {
    border-radius: 0;
    height: auto;
    padding: 8px 12px;
  }
  .btn {
    border-radius: 2px;
    text-transform: uppercase;
  }
}