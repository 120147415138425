.masthead {
  background: $blue-dark;
  color: #fff;
  padding: 15px 0;
  &.has-subnav {
    margin-bottom: 0;
  }
  // .branding-wrapper {
		// padding: 15px 0;
		// background: $blue-dark;
  // }
  // .nav-wrapper {
	 //  background: $neutral-color-dark;
  // }
  .row {
	  margin: 0;
  }
  // .container {
  //   max-width: 970px;
  // }
  .branding {
	  padding: 0 20px;
  }
  h1 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 3px;
    font-weight: normal;
    .light-theme.agm-screen & {
      color: #333;
    }
  }
  h2 {
    color: rgba(255,255,255,0.5);
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    .light-theme.agm-screen & {
      color: rgba(0,0,0,0.3);
    }
  }
  // .links {
  //   a {
	 //    color: #fff;
	 //    font-size: 14px;
	 //    font-weight: 300;
	 //    text-transform: uppercase;
	 //    margin: 0 1px 0 0;
	 //    padding: 18px 20px;
	 //    display: block;
	 //    float: left;
	 //    &:hover, &:focus {
		// 		background: $blue-light;
		//     text-decoration: none;
	 //    }
	 //    &.active {
		//     color: $neutral-color-dark;
		//     background: #f0f0f0;
		//   }
	 //    &.btn {
		// 		margin: 7px 0 !important;
		// 	  padding: 10px 30px !important;
	 //    }
	 //    &.logout {
		//     color: $blue-light;
		//     float: right;
	 //      &:hover, &:focus {
	 //        color: $blue-dark;
	 //        background: #fff;
	 //      }
	 //    }
	 //    ul {
		//     margin: 0;
		//     padding: 0;
		//     list-style: none;
		//     display: inline-block;
		//     li {
		// 	  	margin: 0;
		// 	  	padding: 0 0 0 10px;
		// 	  	line-height: 1;
		// 	  	display: inline-block;
		// 	  	i {
		// 		  	margin-right: 3px;
		// 	  	}
		// 	  	&:last-of-type {
		// 		  	padding-right: 0;
		// 		  	i {
		// 			  	font-size: 14px;
		// 			  	padding-top: 1px;
		// 			  	float: left;
		// 		  	}
		// 	  	}
		//     }
	 //    }
  //   }
  // }
}

.agm-phone {
  margin-right: 15px;
  //display: flex;
  justify-content: center;
  margin-bottom: 3px;
  @include MQ(L) {
    display: block;
  }
}

.agm-phone-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
  margin-top: 5px;
  margin-right: 3px;
  .light-theme & {
    color: rgba(0,0,0,0.4);
  }
}

.links-with-phone {
  @include MQ(L) {
    display: flex;
    align-items: center;
  }
}

.agm-phone-number {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.54px;
  line-height: 18px;
  .light-theme & {
    color: #333;
    font-weight: normal;
  }
}

.sub-nav-wrapper {
  background: darken($blue-dark, 5%);
  margin-bottom: 30px;
}

.sub-nav {
  // text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.sub-nav-link {
  font-size: 14px;
  padding: 15px 40px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    color: #fff;
    background: darken($blue-dark, 10%);
  }
  &.active, &:focus {
    background: $link-color;
    color: #fff;
    text-decoration: none;
    .sub-menu-icons {
      color: rgba(0,0,0,0.5);
    }
  }
}

.sub-menu-icons {
  color: rgba(255,255,255,0.5);
  padding-left: 5px;
}

.sub-menu-icon {
  padding-left: 3px;
  padding-right: 3px;
  i {
    margin-right: 2px;
  }
}

.masthead-links {
  @include MQ(Sneg) {
    float: none;
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
}

.footer-bg {
  background: #242424 !important;
  height: 95px;
  h1 {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 25px;
    margin-bottom: 6px;
  }
  h2 {
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 15px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 992px) {
  .agm-screen .masthead .logo {
    float: none !important;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: auto;
    text-align: center;
  }

}

@media screen and (max-width: 768px) and (min-width: 520px) {
  .links-wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .agm-screen .masthead .main_controls a {
    padding: 9px 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .agm-actions .card-link.footer-box {
    padding: 5rem 15px !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 80% !important;
  }
}

@media only screen and (max-width: 519px) {
  .links-wrapper {
    display: block;
    margin-top: 1rem;
  }
  .main_controls {
    float: none !important;
  }
  .agm-screen .masthead-links a {
    display: inline-block;
    width: 220px;
  }
}

body {
  margin-bottom: 0 !important;
  background:#fff !important;
}

h2.footer-tagline a {
  color: #fff;
  text-decoration: none;
}
.agm-phone-number {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.54px;
  //line-height: 15px;
}

.agm-phone-number .help-exit-color span {
  min-width: 65px;
  display: inline-block;
  font-size: 10px;
}